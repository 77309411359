@import "../../styles/variables.scss";
@import "../../styles/functions.scss";

.thumbsSlider{
    display: grid;
    grid-template-columns: 120px 1fr;
    grid-auto-rows: auto;
    gap: spacing(2.4);
    max-width: 736px;
    width: 100%;
    height: 548px;
}

.viewer{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    background: rgba($color: #000000, $alpha: 0.6);
    transition: 0.2s ease-in-out all;
    visibility: hidden;
    z-index: 1001;
    &.open{
        visibility: visible;
    }
    & .background{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    & .close{
        position: absolute;
        top: spacing(4);
        right: spacing(4);
        padding: spacing(0.5);
        background: rgba($color: #FCFCFC, $alpha: 0.5);
        z-index: 4;
        &:hover{
            background: rgba($color: #FCFCFC, $alpha: 0.6);
        }
        & .icon{
            width: 32px;
            color: $fontColor;
        }
    }
    & .item{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto !important;
        width: 100% !important;
        height: 100% !important;
        & label{
            position: relative;
            margin: 0 auto;
            max-width: 90vw;
            height: 94vh;
            &:has(> .checkbox:checked){
                max-width: 100vw;
                height: 100vh;
            }
        }
        & .checkbox{
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
            z-index: 1000;
            cursor: zoom-in;
            &:checked{
                cursor: zoom-out;
                & ~ img {
                    transform: scale(1.75);
                    cursor: zoom-out;
                }
            }
        }
        & img{
            object-fit: contain;
            transition: transform 0.25s ease;
            cursor: zoom-in;
        }
    }
    & .arrow{
        position: absolute !important;
        padding: spacing(0.5);
        background: rgba($color: #FCFCFC, $alpha: 0.5);
        z-index: 4;
        &:hover{
            background: rgba($color: #FCFCFC, $alpha: 0.6);
        }
        &.prev{
            left: spacing(4);
        }
        &.next {
            right: spacing(4);
        }
        & .icon{
            width: 32px;
            color: $fontColor;
        }
    }
}

.preview{
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    width: 100%;
    height: 100%;
    user-select: none;
    & img{
        object-fit: cover;
        border-radius: 0.5rem;
    }
    & .skeleton{
        width: 100%;
        height: 100%;
        cursor: initial;
    }
    & .item{
        cursor: zoom-in;
    }
}

.thumbs{
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    & .item{
        margin: 0 0 spacing(2) !important;
        width: 120px !important;
        height: 128px !important;
        cursor: pointer;
        &:hover{
            filter: brightness(0.8);
        }
        &:active{
            filter: brightness(0.9);
        }
        & img{
            object-fit: cover;
            border-radius: 0.5rem;
        }
        & .skeleton{
            width: 100%;
            height: 100%;
        }
    }
    & .quantity{
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 0.5rem;
        & p{
            position: absolute;
            font-size: $extraLargeFontSize;
            font-weight: 500;
            color: $secondFontColor;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0;
            width: 100%;
            height: 100%;
            background-color: rgba($color: #111111, $alpha: 0.5);
            z-index: 4;
            border-radius: 0.5rem;
        }
        & img{
            width: 100%;
            height: 100%;
            border-radius: 0.5rem;
            z-index: 3;
        }
    }
}

.normalSlider{
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    & .noSlide{
        display: flex;
        align-items: center;
        gap: spacing(3);
    }
    & .arrow{
        position: absolute;
        box-shadow: $boxshadow;
        z-index: 4;
        background-color: #FDFDFC;
        &:hover{
            background: rgba($color: #FCFCFC, $alpha: 0.8) !important;
        }
        &.prev{
            left: spacing(-3);
        }
        &.next {
            right: spacing(-3);
        }
        & .icon{
            width: 32px;
            color: $fontColor;
        }
    }
    .pages{
        bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: spacing(2);
        margin: 0 auto;
        & .page{
            margin: 0;
            width: 0.5rem;
            height: 0.5rem;
            background: rgba($color: #1A1C21, $alpha: 0.5);
            border-radius: 100px;
        }
        & .pageActive{
            background: $thirdBackgroundColor;
        }
    }
    & .item{
        width: auto;
        & img{
            object-fit: cover;
            cursor: pointer;
            user-select: none;
        }
        & .skeleton{
            width: 100%;
            height: 100%;
        }
    }
}

@media screen and (max-width: 1024px){
    .thumbsSlider{
        display: flex;
        max-width: 100%;
        height: 555px;
    }
    .pages{
        bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: spacing(2);
        margin: 0 auto;
        .page{
            margin: 0;
            width: 0.5rem ;
            height: 0.5rem ;
            background: rgba($color: #1A1C21, $alpha: 0.5);
            border-radius: 100px;
        }
        .pageActive{
            background: $thirdBackgroundColor;
        }
    }
    .preview{
        & img{
            border-radius: 0;
        }
    }
    .thumbs{
        display: none;
    }
}