@import "../../styles/variables.scss";
@import "../../styles/functions.scss";

footer {
    display: flex;
    flex-direction: column;
    background: $thirdBackgroundColor;
    position: relative;
    border-top: 1px solid #CBD5E1;
    & > *{
        font-family: "Poppins";
    }
    & .footerNav{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: spacing(10) spacing(8);
        padding: spacing(8) spacing(8) spacing(9);
        & .item{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: spacing(2);
            & ul{
                display: flex;
                flex-direction: column;
                align-items: start;
                gap: spacing(2);
                list-style: none;
                margin: 0;
                padding: 0;
            }
            & .title,
            & .link{
                font-size: $normalFontSize;
                color: $secondFontColor;
                margin: 0;
                &.active{
                    text-decoration: underline;
                    text-decoration-thickness: 1px;
                    text-underline-offset: 2.5px;
                    text-decoration-color: $thirdFontColor;
                }
            }
            & .title{
                font-weight: 400;
            }
            & .link{
                font-weight: 200;
                &:hover{
                    text-shadow: 0 0 1px $secondFontColor, 0 0 1px $secondFontColor;
                }
            }
        }
    }
    & .footerContent{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: spacing(2.5) spacing(8);
        background: $backgroundColor;
        & > .leftFooter{
            & > p{
                color: #000000;
                width: max-content;
                font-size: $smallFontSize;
            }
        }
        & .rightFooter{
            display: flex;
            align-items: flex-end;
            gap: spacing(2);
            flex-direction: column;
            align-self: flex-start;
            & > .rowFooter{
                display: flex;
                flex-direction: row;
                gap: spacing(4);
                font-size: $smallFontSize;
                color: $fontColor;
                font-weight: 400;
                align-items: center;
                margin: 0;
                & .aLink:hover{
                    cursor: pointer;
                }
                & .aLink{
                    color: #000000;
                    width: max-content;
                }
                & > .socialNetworksContainer{
                    display: flex;
                    flex-direction: row;
                    gap: spacing(2);
                    & > img:hover{
                        cursor: pointer;
                    }
                }
                & > p{
                    width: max-content;
                    & > span{
                        text-decoration: underline;
                        font-weight: 500;
                    }
                    & > span:hover{
                        cursor: pointer;
                    }
                }
            }
            & p{
                font-size: $smallFontSize;
                color: $fontColor;
                font-weight: 400;
                margin: 0;
            }
        }
    }
}

@media screen and (max-width: 1024px){
    .footerContainer{
        & .footerNav{
            width: auto;
            & .item{
                & .title{
                }
                & .link{
                }
            }
        }
        & .footerContent{
            flex-direction: column;
            padding: spacing(2);
            & > .leftFooter{
                align-items: center;
                display: flex;
                flex-direction: column;
                & > img{
                    height: 26px;
                }
                & > p{
                    color: #000000;
                    width: max-content;
                    font-size: $smallFontSize;
                }
            }
            & .rightFooter{
                flex-direction: column-reverse;
                align-self: center;
                & p{
                    //font-size: $extraSmallFontSize;
                }
                & > .rowFooter{
                    width: 100%;
                    justify-content: center;
                    & > .socialNetworksContainer{
                        display: flex;
                        flex-direction: row;
                        gap: spacing(2);
                        & > img{
                            width: 16px;
                            height: 16px;
                        }
                        & > img:hover{
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}