@import "../../styles/variables.scss";
@import "../../styles/functions.scss";

.dropdown{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    & .title{
        background: none;
        border: none;
        cursor: pointer;
        user-select: none;
        & p{
            font-size: $smallFontSize;
            color: $secondFontColor;
            margin: 0;   
        }
    }
    & > .triangle{
        position: absolute;
        top: 135%;
        display: none;
        width: 40px;
        height: 40px;
        background-color: $backgroundColor;
        transform: rotate(45deg);
        box-shadow: $boxshadow;
        z-index: 9;
        &.open{
            display: flex;
        }
    }
    & .dropdownContainer{
        position: absolute;
        z-index: 99999999999999;
        top: 150%;
        display: none;
        padding: spacing(3);
        max-width: 380px;
        width: max-content;
        min-width: 100px;
        border-radius: 0.5rem;
        background-color: $backgroundColor;
        box-shadow: $boxshadow;
        &.open{
            display: flex;
        }
        &.iconType{
            padding: spacing(1) spacing(2);
        }
    }
}