@import "../../../styles/variables.scss";
@import "../../../styles/functions.scss";

.inputsContainer {
    position: relative;
    flex: 1;
    display: flex;
    align-items: stretch;
    margin: 0 0 spacing(4);
    width: 100%;
    border-radius: 20px;
    &.noImages{
        flex: initial;
        margin: auto;
        width: 256px;
        height: 250px;
    }
    & .fileUploader{
        position: absolute;
        flex: 1;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        padding: 0 spacing(2) 0 0;
    }
    & > .toogleButtonGroup {
        justify-content: center;
        margin: 0 spacing(6);
        & .toogleButton {
            flex: 1;
            max-width: 240px;
        }
    }
    & > .fileUploader {
        align-self: unset;
        & > label > div > div{
            flex-wrap: wrap;
            padding: spacing(2) spacing(2) 0 0;
            overflow-y: auto;
        }
    }
}

.submitButton{
    margin: auto 0 0 !important;
}

.text{
    margin: -40px 0 40px 0;
    text-align: center;
}

.maxImagesWarning {
    color: var(--red-500);
    font-size: 14px;
    margin-top: 8px;
    text-align: center;
    width: 100%;
}