@import "../../../styles/variables.scss";
@import "../../../styles/functions.scss";

.myAccount{
    display: flex;
    flex-direction: column;
    height: 100%;
    & .myAccountContent{
        flex: 1;
        display: flex;
        flex-direction: column;
        padding: spacing(5) spacing(6) spacing(2);
        gap: spacing(2.5);
        & > .line{
            width: 100%;
            height: 1px;
            background: $thirdFontColor;
        }
        & > .imageContainer{
            display: flex;
            align-items: center;
            gap: spacing(4);
            & .imageProfile{
                position: relative;
                width: 118px;
                height: 118px;
                border: 1px solid $borderColor;
                border-radius: 100px;
                overflow: hidden;
                & .icon{
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: spacing(0.75) 0;
                    width: 100%;
                    background: $thirdBackgroundColor;
                    z-index: 2;
                    & img{
                        width: 0.8rem;
                    }
                }
                & input{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    border-radius: 100px;
                    z-index: 3;
                    opacity: 0;
                    cursor: pointer;
                }
                & > img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 100px;
                    z-index: 1;
                }
            }
            & .data{
                display: flex;
                flex-direction: column;
                gap: spacing(0.5);
                width: 70%;
                & .name{
                    margin: 0;
                    font-size: $mediumFontSize;
                    font-weight: 600;
                    text-transform: capitalize;
                    color: $fontColor;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    &.skeleton{
                        width: 20%;
                    }
                }
                & .email{
                    margin: 0;
                    font-size: $normalFontSize;
                    font-weight: 500;
                    color: $fontColor;
                    &.skeleton{
                        width: 40%;
                    }
                }
                & .validateEmail{
                    margin: 0;
                    font-size: $extraExtraSmallFontSize;
                    font-weight: 500;
                    color: $errorColor;
                    cursor: pointer;
                    text-decoration: underline;
                    &.skeleton{
                        width: 40%;
                    }
                }
            }
        }
        & > .infoSegment{
            display: flex;
            align-items: start;
            gap: spacing(3);
            & .title{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: start;
                gap: spacing(1);
                margin: spacing(2.25) 0 0;
                width: 160px;
                height: auto;
                & > p{
                    font-size: $smallFontSize;
                    font-weight: 600;
                    color: $thirdFontColor;
                    margin: 0;
                }
                & .close{
                    display: flex;
                    align-items: center;
                    gap: spacing(0.5);
                    padding: spacing(0.5) spacing(1);
                    background: $warningColor;
                    border-radius: 100px;
                    & .icon{
                        font-size: 14px;
                        color: $secondFontColor;
                    }
                    & > p{
                        font-size: 12px;
                        font-weight: 500;
                        color: $secondFontColor;
                        margin: 0;
                    }
                }
                & .check{
                    display: flex;
                    align-items: center;
                    gap: spacing(0.5);
                    padding: spacing(0.5) spacing(1);
                    background: $primaryColor;
                    border-radius: 100px;
                    & .icon{
                        font-size: 14px;
                        color: $secondFontColor;
                    }
                    & > p{
                        font-size: 12px;
                        font-weight: 500;
                        color: $secondFontColor;
                        margin: 0;
                    }
                }
            }
            & .grid{
                display: grid;
                grid-template-columns: repeat(6, 1fr);
                grid-auto-flow: row;
                gap: spacing(3);
                width: 100%;
                & .full{
                    grid-column: span 6;
                }
                & .half{
                    grid-column: span 3;
                }
                & .two{
                    grid-column: span 2;
                }
                & .input{
                    position: relative;
                     & .close{
                        position: absolute;
                        top: -10px;
                        right: -10px;;
                        padding: spacing(0.5);
                        font-size: 14px;
                        color: $secondFontColor;
                        background: $warningColor;
                        border-radius: 100px;
                    }
                    & .check{
                        position: absolute;
                        top: -10px;
                        right: -10px;;
                        padding: spacing(0.5);
                        font-size: 14px;
                        color: $secondFontColor;
                        background: $primaryColor;
                        border-radius: 100px;
                    }
                }
            }
        }
        & > .buttons{
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: spacing(2);
            padding: spacing(2) 0 0;
            margin: auto 0 0;
        }
    }
}

.deleteModalContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: spacing(2);
    & p{
        font-size: $normalFontSize;
        font-weight: 400;
        color: $fontColor;
    }
    & .buttons{
        display: flex;
        align-items: center;
        gap: spacing(2);
    }
}
@media screen and (max-width: 1024px){
    .myAccount{
        & .myAccountContent{
            padding: spacing(3) spacing(3) spacing(2);
            & > .imageContainer{
                    gap: spacing(2);
                & .imageProfile{
                    width: 64px;
                    height: 64px;
                    border-radius: 64px;
                }
                & .data{
                    & .name{
                        max-width: calc(100vw - 56px - 24px - 48px - 64px - 16px);
                    }
                    & .email{
                        max-width: calc(100vw - 56px - 24px - 48px - 64px - 16px);
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
            & > .infoSegment{
                flex-direction: column;
                & .grid{
                    display: flex;
                    flex-direction: column;
                }
            }
        }
    }
}